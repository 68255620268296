@import "../../../themes/colors.scss";

.datePicker {
	margin-left: 10px;
	min-width: 122px;

	input {
		font-size: 13px;
		color: $dark;
	}

	button {
		padding: 5px;
		margin-right: -12px;

		svg {
			color: $light-3;
		}
	}
}
