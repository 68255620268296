@import '../../../themes/colors.scss';

.contactsSideBar {
  background-color:$pale-gray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 30px 20px 24px;
  text-align: center;

  .searchInput {
    border-radius: 4px;
    border: 1px solid $light-3;
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;

    &:focus {
      border-color: #66afe9;
      box-shadow: 0 0 8px 0 #66afe9;
      outline: none;
    }
  }

  .ulList {
    list-style: none;
    margin: 0;
    overflow-y: auto;
    padding: 2px;
  }
}
