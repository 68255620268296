@import "../../../themes/colors.scss";

.modalContent {
	background: $pale-gray !important;

	.calendar {
		padding: 10px 0 10px 0;
		width: 95%;
	}
}
