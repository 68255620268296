@import "../../themes/colors.scss";

.ModalDateSelector {
	align-items: center;
	margin: 30px 0;
	color: $dark;
	font-weight: 600;
	font-size: 14px;

	svg {
		color: $cornflower-blue;
	}
}
