@import "../../../../../../../themes/colors.scss";

%card-style {
	margin-top: 20px;
	border: none;
	box-shadow: none;
	border-radius: 20px;
}

%title-style {
	color: $dark;
	font-size: 13px;
	letter-spacing: normal;
	font-weight: bold;
	text-transform: uppercase;
}

.preview {
	.alert {
		margin-top: 21px;
		padding: 0 16px;
		border-radius: 20px;
		font-size: 13px;
		color: $brownish-grey;
		border: solid 1px rgba(248, 164, 62, 0.3);
	}

	.titleCard {
		@extend %card-style;
		padding: 38px 47px;

		.text {
			font-size: 36px;
			font-weight: bold;
			color: $brownish-grey;
		}
	}

	.dateCard {
		@extend %card-style;
		padding: 20px 30px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		.title {
			@extend %title-style;
			margin-right: 38px;
		}

		.input {
			max-width: 122px;
		}
	}

	.patientInfoCard {
		@extend %card-style;
		padding: 17px 30px;

		.title {
			@extend %title-style;
		}
	}
}
