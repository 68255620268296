@import '../../themes/colors.scss';
@import "../../themes/values.scss";

.smallCard {
	box-sizing: border-box;
	width: 28vw;
	margin-right: 2vw;
	margin-bottom: 2vh;
	padding: 6vh 2vw 6vh 2vw;
	box-shadow: 0 2px 3px 0 $card-shadow;

	@media (max-width: $first-break-point) {
		width: 87vw;
	}	

	.cardContent {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		padding: 0;
		margin: 0;		
		justify-content: normal;
		align-content: stretch;
		align-items: stretch;

		@media (max-width: $first-break-point) {
			justify-content: center;
			align-content: center;
			align-items: center;
			flex-wrap: wrap;
		}	

		.cardImage {
			height: calc(3vmax + 50px);
			width: calc(3vmax + 50px);
			margin-right: 2vw;
		}

		.cardTextContainer {
			text-align: left;
			
			@media (max-width: $first-break-point) {
				text-align: center;
			}

			.cardTextTitle {
				margin: 0 auto 5px;
				font-family: 'Source Sans Pro';
				font-size: 22px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: $dark;
			}

			.cardText {
				margin-top: 5px;
				margin-bottom: 0;
				font-family: 'Source Sans Pro';
				font-size: 17px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.53;
				letter-spacing: normal;
				color: $dark;
			}
		}
	}
}
