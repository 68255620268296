@import '../../themes/colors.scss';
@import "../../themes/values.scss";

.footer {
	box-sizing: border-box;
	display: flex;
	flex-grow: 1;
	background-color: $footer-main-color;
	justify-content: space-between;
	height: 90px;
	padding: 30px 90px 30px;

	@media (max-width: $first-break-point) {
		height: 60px;
		padding: 20px;
	}	

	.footerText {
		margin: 0;
		font-family: 'Source Sans Pro';
		font-size: clamp(11px, 1.3vw, 17px);
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.53;
		letter-spacing: normal;
		color: $light-2;
	}

	.logo {
		background-image: url('../../assets/images/pragma-logo-footer.svg');
		height: 32px;
		width: 100px;

		@media (max-width: $first-break-point) {
			background-image: url('../../assets/images/pragma-logo-footer-mobile.svg');
			height: 20px;
			width: 14px;
		}
	}
}
