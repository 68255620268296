.loadingSpinner {
	height: 4vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.loadingSpinner {
		animation: loadingSpinner-spin infinite 20s linear;
	}
}

@keyframes loadingSpinner-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
