@import "../../../themes/colors.scss";

%text-style {
	color: $dark;
	font-family: "Source Sans Pro";
	font-size: 13px;
	letter-spacing: normal;
}

%transition-style {
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

%card-style {
	border: none;
	box-shadow: none;
	border-radius: 20px;
}

.profileCard {
	.cardForm {
		padding: 30px;
		max-width: 332px;
		@extend %card-style;

		&.maxWidth {
			max-width: 100%;
		}

		.content {
			&.maxWidth {
				max-width: 320px;
			}

			.header {
				display: flex;
				justify-content: space-between;

				&Title {
					@extend %text-style;
					font-weight: bold;
					text-transform: uppercase;
				}

				&EditButtom {
					@extend %text-style;
					color: $cornflower-blue;
					font-weight: 600;
					margin-right: -8px;
				}
			}

			.label {
				@extend %text-style;
				opacity: 0.5;
				min-width: 120px;
			}

			.input {
				@extend %text-style;
				text-align: end;

				&Root {
					margin-bottom: 20px;
				}

				&Underline::before {
					border-bottom-color: $light-3;
				}
			}
		}
	}
}

.container {
	border: 0.5px solid $signature-border-color;
	background-color: $signature-background-color;
	margin-top: 10px;
	display: flex;
	text-align: center;
	justify-content: center;

	.image {
		margin: 0 auto;
		height: auto;
	}
}
