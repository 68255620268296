@import '../../../themes/colors.scss';

.chatContent {
  background-color: $light-2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  text-align: center;


  .chatUserCard {
    box-shadow: none;
    border-bottom: 1px solid $light-3
  }

  .bubbleDate {
    align-self: center;
    background-color: $card-shadow;
    border-radius: 16px;
    color: $dark-sky-blue;
    width: fit-content;
    padding: 5px 15px;
  }

  .messages {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    overflow-y: scroll;
    padding: 0;
  }

  .chatForm {
    align-items: baseline;
    border-top: 1px solid $light-3;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding: 10px;
    text-align: left;

    .inputMessage {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      box-sizing: border-box;
    }
  }
}