@import "../../themes/colors.scss";

.ModalBottomBar {
	display: flex;
	align-items: center;

	.avatar {
		width: 20px;
		height: 20px;
		background-color: black;

		.icon {
			width: 60%;
			height: 60%;
		}
	}

	.text {
		color: $dark;
		font-weight: 600;
		font-size: 14px;
		margin: 13px 10px;
	}
}
