@import '../../themes/colors.scss';

.heading {	
	padding: 0 2vw 0 2vw !important;
	border-radius: 0.4vw !important;
	box-shadow: 0.1vw 0.2vw 0.3vw 0 $card-shadow;
	border: solid 1px rgba(221, 229, 248, 0.6);
	background-color: $light-2;

		p {
			font-family: Source Sans Pro;
			font-weight: 600;
			font-size: 17px;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $dark;
		}

		svg {
			color: $cornflower-blue !important;
		}

		div {
			margin: 0.3vw 0;
		}

		.headingTitle {
			margin: 0.2vw 0;

			&.Mui-expanded {
				margin: 0;
			}
		}
}

.body {
	padding: 2vw 2vw 2vw 2vw !important;
	background-color: $pale-gray;
	font-weight: 300;
	line-height: 1.53;
	letter-spacing: normal;
	box-shadow: none !important;
}

.head {
	border-radius: 0.3vw !important;
	border: solid 1px rgba(221, 229, 248, 0.6);
	box-shadow: none !important;
	margin-bottom: 7px!important;
}
