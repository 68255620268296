@import '../../../themes/colors.scss';

.card {
	box-shadow: 0 2px 4px 0 #dbe4f9;
	border: solid 1px var(--pale-grey);
	background-color: var(--light-2);
	border-radius: 6px;

	.content {
		display: flex;
		justify-content: space-around;
		
		.info {
			display: flex;
			flex: 1;
			justify-content: flex-start;
			align-items: center;

			.cardMedia {
				margin-right: 20px;
			}

			.userName {
				display: flex;
				flex-direction: column;
				.title {
					font-size: 16px;
				}
				.subTitle {
					font-size: 12px;
				}
			}
		}
	}
}