@import "../../themes/colors.scss";

.box {
	display: flex;
	align-items: center;
	height: 46px;
	background-color: rgba($title-box-bg, 0.05);
	border-radius: 20px;
	margin-bottom: 15px;
	font-family: "Source Sans Pro";

	.text {
		width: 100%;
		font-size: 13px;
		padding: 0 24px;
		align-content: center;
	}

	.selector {
		display: flex;
		padding-left: 20px;

		.label {
			color: $cornflower-blue;
			font-size: 13px;
			font-weight: 600;
		}

		.checkbox {
			color: $light-3;

			&Checked {
				color: $dark-sky-blue;
			}
		}
	}
}
