@import '../../themes/colors.scss';

.badge {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: $light-2;
	font-size: 12px;
	font-weight: bold;
	top: 0;
	right: 0;
}

.small {
	width: 10px;
	height: 10px;
}

.medium {
	width: 24px;
	height: 24px;
}

.colorRed {
	background-color: $dark-peach;
}

.colorGreen {
	background-color: $green-blue;
}