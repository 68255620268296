@import "../../../themes/colors.scss";

.container {
	height: 86px;

	.card {
		border-radius: 20px;
		border: none;

		&Content {
			display: flex;
			justify-content: space-between;

			&Info {
				display: flex;
				align-items: center;

				&Avatar {
					height: 50px;
					width: 50px;
				}

				> span {
					font-family: "Source Sans Pro";
					font-size: 18px;
					font-weight: 600;
					color: $dark;
					margin-left: 10px;
				}
			}

			&Button {
				display: flex;
				margin-top: 5px;
				align-items: center;

				> Button {
					display: flex;
					align-items: self-start;
					color: $cornflower-blue;
					font-family: "Source Sans Pro";
					font-size: 13px;
					font-weight: 600;
				}
			}
		}
	}
}
