@import "../../themes/colors.scss";

.MuiButton-root.googleSigninButton {
	background-color: $light-2;
	height: 60px;
	border-radius: 2px;
	box-shadow: -2px 5px 6px 0 rgba(214, 217, 231, 0.29);
	display: flex;
	justify-content: flex-start;
	text-transform: none;
	font-size: calc(10px + 0.4vmin);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.4px;
	color: $textfield-font-color;
	min-width: 150px;
}
