@import '../../../themes/colors.scss';

.outgoingMessage {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  
  .message {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    
    .messageBubble {
			font-family: "Source Sans Pro";
      align-self: flex-end;
      background-color: $dark;
      border-radius: 50px;
      color: $light-2;
      font-size: 13px;
      max-width: 100%;
      padding: 14px 22px 16px 26px;
      word-break: break-word;
      word-wrap: break-word;
    }
  
    .messageTime {
      color: $textfield-font-color;
      font-size: 12px;
      margin: 5px 15px 0px 0px;
      align-self: flex-end;
    }
  }
}
