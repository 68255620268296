@import "../../themes/colors.scss";
@import "../../themes/values.scss";

.container {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-evenly;

	@media (max-width: $first-break-point) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.imageContainer {
		&--right {
			order: 2;

			@media (max-width: $first-break-point) {
				order: 0;
			}
		}
	}

	.content {
		max-width: 475px;

		@media (max-width: $first-break-point) {
			width: 87, 5vw;
			padding-top: 35px;
		}

		&--right {
			margin-right: 7vw;

			@media (max-width: $first-break-point) {
				margin: 0 5.3vw 0 7.1vw;
			}
		}

		&--left {
			margin-left: 7vw;

			@media (max-width: $first-break-point) {
				margin: 0 5.3vw 0 7.1vw;
			}
		}

		.contentHighlight {
			box-sizing: border-box;
			max-width: 200px;
			text-align: center;
			height: 35px;
			padding: 8px 20px;
			border-radius: 17px;
			background-color: #edf8fb;

			.highlightText {
				font-family: "Source Sans Pro";
				font-size: clamp(12px, 1vw, 15px);
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				opacity: 1;
				color: $dark-sky-blue;
			}
		}

		.contentTitle {
			margin: 20px 0;
			font-family: "Source Sans Pro";
			font-size: clamp(20px, 1.8vw, 26px);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $dark;
		}

		.contentDescription {
			font-family: "Source Sans Pro";
			font-size: clamp(12px, 1.2vw, 17px);
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.53;
			letter-spacing: normal;
			color: $dark;
		}

		.bottomComponent {
			margin-top: 30px;
		}
	}
}
