@import "../../themes/colors.scss";

.teamBehindSection {
	padding-top: 70px;
	background-color: $pale-gray;

	.teamBehindTitle {
		font-family: "Source Sans Pro";
		margin-top: 0;
		margin-bottom: 30px;
		font-size: 38px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: $dark;
	}

	.teamBehindCardsContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		.teamBehindCard {
			margin: 50px 20px;
		}
	}
}
