@import "../../../../../themes/colors.scss";

.resources {
	.iconFile {
		color: $light-3;
	}

	.iconActions {
		color: $light-3;
	}

	.avatar {
		width: 30px;
		height: 30px;
		font-size: 15px;
		font-weight: bold;		
	}

	.closeIcon {
		color: $cornflower-blue;
		font-size: 13px;
		font-weight: 600;
		margin-right: 0;
		margin-left: auto;
	}
}
