@import "../../../themes/colors.scss";

.notes {
	display: flex;
	flex-grow: 1;

	.card {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		border: none;
		border-radius: 20px;
		padding: 31px 29px 40px 50px;

		.content {
			display: flex;
			flex-direction: column;
			height: 100%;

			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				> span {
					font-size: 18px;
					font-weight: 600;
					color: $dark;
				}
			}

			.body {
				margin-top: 40px;
				display: flex;
				height: 100%;
				justify-content: center;

				.steppers {
					margin-right: 36px;
				}

				.noteDetail {
					display: flex;
					flex: 1;
				}

				.noData {
					font-size: 16px;
					font-weight: bold;
					color: $brownish-grey;
					text-align: center;
				}
			}
		}
	}
}
