@import "../../../themes/colors.scss";

.container {
	padding: 26px;
	padding-top: 0;

	.appBar {
		background-color: transparent;
		border: none;
		border-bottom: 3px solid rgba($cornflower-blue, 0.1);

		.tabs {
			&Content {
				justify-content: space-around;
			}

			&Indicator {
				display: flex;
				height: 3px;
				justify-content: center;
				background-color: transparent;

				& > span {
					width: 100%;
					background-color: $cornflower-blue;
				}
			}

			.tab {
				text-transform: none;
				color: $dark;
				font-size: 13px;
				font-weight: bold;
				padding: 0 30px;
				width: 100%;

				&:focus {
					color: $cornflower-blue;
				}
			}
		}
	}

	.writeContainer {
		border: 0.5px solid $signature-border-color;
		background-color: $signature-background-color;
		height: 100px;
		margin-top: 10px;
		text-align: center;
		justify-content: center;
		flex-direction: column;
	}

	.signatureDrawCanvasContainer {
		border: 1px solid $light-3;
		width: 100%;
		margin-top: 10px;
		background-color: $signature-background-color;
	}
}
