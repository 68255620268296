@import "../../../themes/colors.scss";

.resourceLibrary {

	.search {
		display: flex;
		align-items: center;
		color: $blue-gray;

		.divider {
			margin: 25px;
			height: 28px;
			align-self: auto;
		}

		.icon {
			padding-right: 16px;
		}
	}
	
	.container {
		display: inline;
		
		.grid {
			width: 100%;
			padding: 20px 10px 0 20px !important;

			.shareIcon {
				color: $cornflower-blue;
				font-size: 13px;
				font-weight: 600;
				margin-right: 0;
				margin-left: auto;
			}

			.column {
				height: clamp(500px, 81vh, 1010px);
    		margin-bottom: 20px;
    		overflow-y: auto;
			}
		}
	}
}
