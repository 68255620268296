@import "../../../themes/colors.scss";

.medContainer {
	margin: 5px -10px;
	display: flex;
	align-items: center;
}

.timeSelector {
	margin: 5px 20px !important;
	align-items: center;
	.buttonGroup {
		.button {
			height: 40px;
			&:disabled {
				background-color: lighten($color: $brownish-grey, $amount: 50);
			}
		}
	}

	svg {
		color: $cornflower-blue;
	}
}

.text {
	color: $dark;
	font-size: 14px;
	font-weight: 600;
}

.title {
	color: $dark;
	font-size: 16px;
	font-weight: 700;
}
