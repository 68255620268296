@import "../../../themes/colors.scss";

.container {
	.card {
		border-radius: 20px;
		padding: 16px;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $brownish-grey;
	}

	.cardContent {
		overflow: auto;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		gap: 5px;
	}
}