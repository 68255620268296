.content {
	display: flex;
	flex-direction: column;
	margin-top: -15px;
	
	.date {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 15px;
	}
}
