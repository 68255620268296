@import "../../../themes/colors.scss";

%bg-style {
	background-color: rgba($dark-sky-blue, 0.15);
	border: solid 1px $dark-sky-blue;
	border-radius: 4px 4px 0 0;

	> .date {
		background-color: transparent !important;
	}
}

.monthYearSelector {
	display: flex;
	justify-content: space-between;
	margin: 30px 0;

	.button {
		color: $cornflower-blue;
		background-color: rgba($dark-sky-blue, 0.1);
		width: 32px;
		height: 32px;
	}

	span {
		font-family: "Source Sans Pro";
		font-size: 18px;
		font-weight: 600;
		color: $dark;
		text-transform: uppercase;
	}
}

.dateCard {
	display: flex;
	flex-direction: column;
	box-shadow: 0 2px 4px 0 $card-shadow;
	margin-bottom: 10px;
	margin-right: 10px;

	.cardContent {
		display: flex;
		height: 74px;
		padding: 0px;
		align-items: center;
		position: relative;

		&Background {
			@extend %bg-style;
		}

		&BackgroundBorderRadius {
			@extend %bg-style;
			border-radius: 4px;
		}

		&:last-child {
			padding-bottom: 0px;
		}

		.date {
			display: flex;
			flex-direction: column;
			font-family: "Source Sans Pro";
			font-size: 12px;
			font-weight: 600;
			color: $dark-sky-blue;
			text-transform: uppercase;
			justify-content: center;
			text-align: center;
			height: 74px;
			width: 75px;
			background-color: rgba($dark-sky-blue, 0.15);

			span {
				font-size: 30px;
			}
		}

		.time {
			display: flex;
			flex-direction: column;
			font-family: "Source Sans Pro";
			font-size: 16px;
			color: $dark;
			padding-left: 21px;

			span {
				font-family: "Source Sans Pro";
				font-size: 10px;
				color: $blue-gray;
			}
		}

		.avatar {
			width: 20px;
			height: 20px;
			background-color: $dark-sky-blue;
			position: absolute;
			left: 65px;

			.icon {
				width: 60%;
				height: 60%;
			}
		}
	}

	.cardActions {
		justify-content: center;
		border: solid 1px $dark-sky-blue;
		border-radius: 0 0 4px 4px;
		border-top: none;
		padding-left: 40px;
		padding-right: 40px;
	}

	&NoData {
		font-family: "Source Sans Pro";
		font-size: 16px;
		font-weight: bold;
		color: $brownish-grey;
		text-align: center;
	}
}
