@import "../../themes/values.scss";

.container {
	display: none;

	@media (max-width: $first-break-point) {
		display: block;
	}

	.carrousel {
		div > button {
			&:first-child  { 
					background-color: transparent !important;
			}
		}
	}
}
