@import "../../../themes/colors.scss";

.pagination {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	align-self: baseline;
	padding-right: 0px;

	> button {
		margin-right: 5px;
	}

	.button {
		min-width: 36px;
		height: 36px;
		padding: 0px;
		background-color: rgba($light-2, 0.64);

		&Icon {
			margin-left: 0px;
			margin-right: 0px;
		}

		&Primary {
			border-color: $light-3;
		}
	}

	.text {
		font-size: 13px;
		margin-right: 23px;
		color: $dark;

		> span {
			font-weight: bold;
		}
	}
}
