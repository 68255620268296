@import '../../themes/values.scss';

.chatContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    display: flex;
    flex-direction: row;
    height: calc(100vh - $topbar-height);
    
    & > aside {
      flex: 1;
    }
  
    & > div {
      flex: 3;
    }    
  }
}
