@import '../../../themes/colors.scss';

.incomingMessage {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  .message {
    display: flex;
    flex-direction: column;
    max-width: 50%;

    .messageBubble {
			font-family: "Source Sans Pro";
      align-self: flex-start;
      background-color: $pale-gray;
      border-radius: 50px;
      color: $dark;
      font-size: 13px;
      max-width: 100%;
      padding: 14px 22px 16px 26px;
      word-break: break-word;
      word-wrap: break-word;
    }
  
    .messageTime {
      font-size: 12px;
      color: $textfield-font-color;
      margin: 5px 0px 0px 15px;
      align-self: flex-start;
    }
  }
}
