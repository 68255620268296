@import "../../themes/colors.scss";

.title {
	font-size: 18px;
	font-weight: 600;
	color: $dark;
}

.subtitle {
	margin-top: 10px;
	font-size: 13px;
	font-weight: normal;
	color: $dark;
}
