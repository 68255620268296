@import '../../themes/colors.scss';

.faqTitle {
	margin: 56px 735px 56px 90px;
	font-family: 'Source Sans Pro';
	font-size: 38px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.32;
	letter-spacing: normal;
	color: $dark;
}

.faqItems {
	margin: 56px 370px 190px;
}