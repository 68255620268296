@import "../../themes/colors.scss";
@import "../../themes/values.scss";

.postContainer {
	.postHeader {
		width: 100vw;
		height: 48.61vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media (max-width: $first-break-point) {
			height: 110vw;
		}

		.tags {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.tagContainer {
				margin: 0 3px 20px;

				@media (max-width: $first-break-point) {
					margin-bottom: 0;
				}

				.tag {
					color: $dark-sky-blue;
					background-color: unset;
					box-shadow: inset 0 0 0 2000px rgba(78, 190, 215, 0.1);
				}
			}
		}

		.title {
			font-family: "Source Sans Pro";
			font-size: clamp(28px, 2.64vw, 38px);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.32;
			letter-spacing: normal;
			text-align: center;
			color: $light-2;
			width: 42vw;

			@media (max-width: $first-break-point) {
				width: 84vw;
				line-height: 1.29;
			}
		}
	}

	.postContent {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 4.6vw 25vw;
		padding-bottom: 120px;
		font-family: "Source Sans Pro";

		@media (max-width: $first-break-point) {
			margin: 9.3vw 10vw;
		}
	}
}
