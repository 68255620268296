@import "../../../themes/colors.scss";

.home {
	.container {
		padding: 26px;

		.requestButton {
			align-self: center;
		}

		.seeAllButton {
			color: $cornflower-blue;
			font-family: "Source Sans Pro";
			font-size: 13px;
			font-weight: 600;
			text-transform: unset;
		}
	}
}
