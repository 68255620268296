@import "../../../themes/colors.scss";

.content {
	display: flex;
	flex-direction: column;
	margin-top: -15px;

	.divider{
		border-top: 1px;
		width: 100%;
		margin-top: 40px;
	}

	.patientName {
		font-size: 24px;
		font-weight: bold;
	}

	.iconButton {
		color: $cornflower-blue;
		font-size: 13px;
		font-weight: 600;
		margin-right: 0;
		margin-left: auto;
	}

	.labelsContainer {
		margin-top: 30px;
	}

	.leftLabel {
		opacity: 0.5;
	}
}
