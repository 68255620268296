@import "../../themes/colors.scss";

.eventCalendar {
	display: flex;
	flex-direction: column;
	height: inherit;
	align-items: center;
	justify-content: center;
}


.phoneIconContainer {
	border: 1px solid $primary-color;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	background-color: #3ca8dc;

	.phoneIcon {
		height: 16px;
		width: 16px;
		color: $primary-color;
	}
}