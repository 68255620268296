@import "../../themes/colors.scss";
@import "../../themes/values.scss";

.blogContainer {
	padding: clamp(28px, 3.89vw, 56px) $default-padding-sides
		clamp(131px, 18.19vw, 262px);

	@media (max-width: $first-break-point) {
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}

	.pageTitleContainer {
		display: flex;
		justify-content: space-between;
		margin: 4vh;

		.pageTitle {
			font-family: "Source Sans Pro";
			font-size: clamp(26px, 2.64vw, 38px);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.32;
			letter-spacing: normal;
			color: $dark;
			margin: 0;
		}

		.filterOption {
			display: none;
			color: $cornflower-blue;
			font-size: clamp(12px, 1.67vw, 16px);
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			cursor: pointer;

			@media (max-width: $first-break-point) {
				display: flex;
				align-items: center;

				.filterIcon {
					width: 24px;
					height: auto;
					margin-right: 4px;
				}
			}
		}
	}

	.blogContent {
		display: flex;
		flex-flow: row nowrap;

		.postContainer {
			display: flex;
			flex-direction: column;
			width: clamp(515px, 53.47vw, 770px);

			@media (max-width: $first-break-point) {
				width: 100%;
			}

			.noPost {
				margin-bottom: clamp(30px, 4.17vw, 60px);
				text-align: center;
			}

			.post {
				margin-bottom: clamp(30px, 4.17vw, 60px);

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.filters {
			margin-left: clamp(40px, 5.55vw, 80px);
			flex: 1;

			@media (max-width: $first-break-point) {
				display: none;
			}

			.filtersText {
				font-family: "Source Sans Pro";
				font-size: clamp(14px, 1.53vw, 22px);
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: $dark;
				margin: 0;
				margin-bottom: clamp(20px, 2.43vw, 35px);
			}

			.noFilter {
				margin: clamp(30px, 4.17vw, 60px);
				text-align: center;
			}

			.checkboxes {
				border-top: 1px solid $filter-border-color;
				border-bottom: 1px solid $filter-border-color;
				height: auto;

				.filter {
					font-size: clamp(12px, 1.18vw, 17px);
					font-weight: 300;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.53;
					letter-spacing: normal;
					color: $dark;
				}
			}
		}
	}
}

.menu {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $light-2;
	z-index: 100;
	overflow-y: scroll;

	&Display {
		display: flex;
		flex-direction: column;
	}

	.menuHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 80px;
		font-family: "Source Sans Pro";
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $light-2;
		padding: 0 25px;
		background-color: #3ca8eb;

		.closeMenuLogoContainer {
			display: flex;
			align-items: center;
			height: 24px;

			.closeMenuLogo {
				cursor: pointer;
				height: 100%;
			}
		}
	}

	.noFilter {
		margin: clamp(30px, 4.17vw, 60px);
		text-align: center;
	}

	.checkboxes {
		padding: 25px;

		.filter {
			font-size: clamp(12px, 1.18vw, 17px);
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.53;
			letter-spacing: normal;
			color: $dark;
		}
	}
}
