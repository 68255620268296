.sessionModalTherapist {
	line-height: 1.85;
	.sectionContainer {
		margin: 20px 0;
	}
}

.bottomContainer {
	margin: 20px 0;
	display: flex;
	align-items: center;
}
