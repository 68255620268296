@import "../../themes/colors.scss";

.table {
	display: flex;
	flex-direction: column;
	width: 100%;

	.header {
		display: flex;
		flex-direction: row;

		&Content {
			display: flex;

			.arrowIcon {
				margin-left: 10px;
				align-self: center;
			}
		}
	}

	.noData {
		margin-top: 15px;
		font-size: 16px;
		font-weight: bold;
		color: $brownish-grey;
		text-align: center;
	}

	.row {
		box-shadow: 0 2px 4px 0 $card-shadow;
		margin-bottom: 8px;
		border-radius: 6px;

		&ActionArea {
			.content {
				display: flex;
				flex-direction: row;
				height: 32px;
				align-items: center;

				div {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		&:hover {
			box-shadow: 0 13px 23px -5px $card-shadow;
			border: solid 1px rgba($dark-sky-blue, 0.3);
			background-color: $light-2;
		}
	}

	.selectedRow {
		box-shadow: 0 2px 4px 0 $card-shadow;
		border: solid 1px $dark-sky-blue;
		background-color: $card-active-bg;
	}
}
