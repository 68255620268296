@import "../../themes/colors.scss";

::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	border-radius: 2px;
	background: white;
}
::-webkit-scrollbar-thumb {
	background: $light-3;
	border-radius: 2px;
}
