@import "../../../../themes/colors.scss";

.steppers {
	width: 250px;

	.connector {
		margin-left: 56px;
	}

	&.checkbox {
		width: 300px;

		.container {
			.step {
				&Label {
					&Active {
						margin-left: 0px;
					}
					&Inactive {
						margin-left: 10px;
					}
				}
				&Content {
					margin-left: 68px;
				}
			}
		}
	}

	.container {
		padding: 0 6px;

		.step {
			&Label {
				&Active {
					border: solid 1px $dark-sky-blue;
					border-radius: 20px;
					background-color: $pale-grey-two;
					padding: 10px 11px;
					margin-left: -10px;

					.text {
						flex: 1;
						align-items: center;
						justify-content: space-between;
						display: flex;

						> span {
							font-size: 13px;
							font-weight: bold;
							text-transform: uppercase;
						}

						.trashIcon {
							color: silver;
							cursor: pointer;

							&:hover {
								color: $dark-sky-blue;
							}
						}
					}
				}

				&Inactive {
					padding-left: 2px;
					font-weight: bold;
					cursor: pointer;

					.text {
						font-size: 13px;
						font-weight: bold;
						text-transform: uppercase;
						color: $dark;
					}

					.dotIcon {
						color: $silver;
					}
				}
			}

			&Content {
				font-size: 13px;
				color: $dark;
			}
		}
	}
}
