@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
@import "../../../themes/colors.scss";

.signature {
	.card {
		border: none;
		box-shadow: none;
		border-radius: 20px;

		.content {
			height: 268px;

			.header {
				display: flex;
				justify-content: space-between;

				.headerEditButton {
					font-size: 13px;
					font-weight: 600;
				}

				.title {
					font-size: 13px;
					font-weight: bold;
					text-transform: uppercase;
				}
			}

			.container {
				border: 0.5px solid $signature-border-color;
				background-color: $signature-background-color;
				margin-top: 10px;
				display: flex;
				text-align: center;
				justify-content: center;
				margin: 20px 40px 40px 40px;

				.label {
					font-family: "Cookie", cursive;
					font-size: 80px;
				}

				.signatureImage {
					margin: 0 auto;
					height: auto;
				}
			}
		}
	}
}
