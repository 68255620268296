@import "../../themes/colors.scss";

.regularTextField {
	width: 100%;

	input {
		opacity: 0.5;
		font-family: "Source Sans Pro";
		font-size: clamp(12px, 1.1vw, 16px);
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: $dark;
		background-color: white;
	}

	.fieldset {
		padding: 2vh 0.5vw;
		border-radius: 6px !important;
		border: solid 1px $border-color !important;
	}
}

.multilineTextField {
	opacity: 0.5;
	font-family: "Source Sans Pro";
	font-size: clamp(12px, 1.1vw, 16px);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: $dark;

	textarea {
		height: 15vh !important;
	}
}
