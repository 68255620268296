@import "../../../themes/colors.scss";

.tasks {
	.container {
		padding: 26px;

		.header {
			.info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				span:nth-child(2) {
					font-size: 18px;
					font-weight: 600;
				}
			}

			.actionButton {
				font-size: 13px;
				text-transform: unset;
				font-weight: 600;
			}
		}

		.table {
			.iconActions {
				color: $light-3;
			}

			.iconFavourite {
				color: $faded-orange;
			}
		}
	}
}
