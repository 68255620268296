@import "../../themes/colors.scss";
@import "../../themes/values.scss";

.functionMain {
	height: clamp(395.5px, 54.93vw, 791px);
	position: relative;
	top: calc(-1 * #{$topbar-height});
	box-sizing: border-box;
	padding-top: calc(#{$topbar-height} + 2.78vw);
	padding-left: $default-padding-sides;
	padding-right: $default-padding-sides;
	background: radial-gradient(
		1650px 1650px at 85% 40%,
		#5393df,
		#4cb5ee 60%,
		#3ca8eb 70%,
		#5393df 70%,
		#45ddff
	);
	border-bottom-left-radius: 50% 13vh;
	border-bottom-right-radius: 50% 13vh;

	.functionMainContent {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;

		.functionsImageContainer {
			order: 2;
			width: clamp(375.5px, 52.15vw, 751px);
			margin-top: clamp(38.5px, 5.35vw, 77px);

			.functionsImage {
				width: 100%;
			}
		}

		.content {
			width: clamp(275.5px, 38.26vw, 551px);
			margin-top: clamp(60px, 8.33vw, 120px);

			.contentTitle {
				font-family: "Source Sans Pro";
				font-size: clamp(19px, 2.64vw, 38px);
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.32;
				letter-spacing: normal;
				color: $light-2;
			}

			.bottomComponent {
				margin-top: clamp(15px, 2.08vw, 30px);
			}
		}
	}
}

.functionContainer {
	.functionCalendar {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.functionCalendarTitle {
			font-family: "Source Sans Pro";
			font-size: clamp(19px, 2.64vw, 38px);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: $dark;
		}

		.calendarCardsContainer {
			display: flex;
			flex-direction: row;
			margin-top: clamp(22px, 3.05vw, 44px);

			.cardContainer {
				box-shadow: 0 0 0 0 transparent !important;
				border: 0px !important;
				width: 28vw;
				min-height: 31vh;
				margin: 5vw 0 0;
				padding: 0 2vw;

				.image {
					width: 7vw;
					height: 7vw;
					position: relative;
					top: -5vw;
					left: 9.5vw;
				}

				%textCommon {
					font-family: "Source Sans Pro";
					font-stretch: normal;
					font-style: normal;
					letter-spacing: normal;
					text-align: center;
					color: $dark;
					position: relative;
					top: -5vw;
				}

				.cardTitle {
					@extend %textCommon;
					margin: 4vh 0 2vh 0;
					font-size: clamp(11px, 1.53vw, 22px);
					font-weight: 600;
					line-height: normal;
				}

				.cardDescription {
					@extend %textCommon;
					font-size: clamp(8.5px, 1.18vw, 17px);
					font-weight: 300;
					line-height: 1.53;
					overflow: hidden;
				}
			}
		}
	}

	.functionMore {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: clamp(135px, 18.75vw, 270px);

		.titleMore {
			font-family: "Source Sans Pro";
			font-size: clamp(19px, 2.64vw, 38px);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: $dark;
		}

		.functionMoreCard {
			margin-top: clamp(54px, 7.5vw, 108px);
		}
	}
}
