@import "../../../../../../../themes/colors.scss";

%card-style {
	border: none;
	box-shadow: none;
	border-radius: 20px;
}

.notes {
	.filter {
		@extend %card-style;
		height: 80px;
		font-size: 13px;
		line-height: 1.85;
		padding: 20px 25px;

		.filterTop {
			display: flex;
			justify-content: space-between;

			.actionButton {
				font-size: 13px;
				text-transform: unset;
				font-weight: 600;
			}
		}

		.filterInput {
			display: flex;
			align-items: center;
			margin-top: 5px;

			> span {
				margin-right: 10px;

				&:nth-last-child(2) {
					margin-left: 10px;
				}
			}
		}
	}

	.steppers {
		@extend %card-style;
		padding: 30px 5px;
		margin-top: 10px;
	}

	.noteDetail {
		> div:nth-child(2) {
			height: 92%;

			& > div {
				background-color: $light-2;
			}
		}
	}
}

.titleBox {
	font-size: 13px;
	letter-spacing: 0.51px;
	font-weight: bold;
	text-transform: uppercase;

	> span {
		font-size: 16px;
		letter-spacing: normal;
		font-weight: normal;
		margin-left: 18px;
		text-transform: none;
	}
}
