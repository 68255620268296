@import "../../themes/colors.scss";

.ModalTab {
	align-items: center;
	margin-top: 10px !important;

	.text {
		color: $dark;
		font-family: "Source Sans Pro";
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		font-size: 14px;
		letter-spacing: normal;
	}

	svg {
		color: $cornflower-blue;
	}
}
