@import '../../../themes/colors.scss';

.content {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.actions {
			display: flex;
			align-items: center;
			gap: 5px;

			.addButton {
				text-transform: none;
			}

			.addIcon {
				color: $cornflower-blue;
			}
	
			.invitedIcon {
				color: $green-blue;
			}
		}
		
		.footer {
			display: flex;
			flex-direction: column;
			gap: 5px;
			.footerText {
				font-weight: bold;
				gap: 1;
			}
		}
}
