@import "../../../themes/colors.scss";

.patients {
	.container {
		padding: 26px;

		.header {
			.actionButton {
				font-size: 13px;
				text-transform: unset;
				font-weight: 600;
			}
		}

		.table {
			.iconActions {
				color: $light-3;
			}
		}
	}
}
