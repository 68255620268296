@import '../../themes/colors.scss';

.plan {
	text-align: center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: 420px;
	max-height: 620px;

	.contentBottom {
		padding: 0.7vw;
		height: 3vh;

		.bottomText{
			font-family: 'Source Sans Pro';
			font-size: 15px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $light-2;
		}
	}

	.planContainer {
		display: flex;
		flex-direction: column;
		flex-flow: column nowrap;
		justify-content: center;	
		padding: 5vw 0 1vw 0;
		box-sizing: border-box;
		text-align: center;

		.contentHighlight {
			box-sizing: border-box;
			margin: 0 5vw 4vh;
		}

		.planPrice {
			margin: 15vh 0.5vw 9vh 0vw;
			font-family: 'Source Sans Pro';
			font-size: 38px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $dark;
		}

		.planUnit {
			font-family: 'Source Sans Pro';
			font-size: 14px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.86;
			letter-spacing: normal;
			color: $dark;
		}

		.planDescription {
			padding: 4vh 4vw 1vh 4vw;
			height: 25vmin;
			p {
				margin: 0;	
				font-family: "Source Sans Pro";
				font-size: 14px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.57;
				letter-spacing: normal;
				text-align: center;
				color: $dark;
			}
		}
	}

	.planButton {
		margin: 1vw 0 1vw 0;
	}
}

.planSelected {
	border-radius: 4.8px;
	box-shadow: 0 10px 18px 0 $card-shadow;
	border: solid 0.8px rgba(78, 190, 215, 0.5);
	background-color: $light-2;
	border-bottom: 0px;

	.contentBottomSelected{
		background-color: $dark-sky-blue;
	}
}
