@import "../../../themes/colors.scss";

%text-style {
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
}

.question {
	margin-top: 20px;

	&Title {
		height: 24px;
		font-size: 18px !important;
		font-weight: bold !important;
		@extend %text-style;

		&Required {
			color: red;
		}
	}

	&Description {
		height: 24px;
		font-size: 14px !important;
		font-weight: normal;
		@extend %text-style;
	}
}
