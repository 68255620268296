.documents {
	.titleBox {
		font-size: 13px;
		font-weight: bold;
		text-transform: uppercase;

		> span {
			font-size: 16px;
			font-weight: normal;
			margin-left: 18px;
			text-transform: none;
		}
	}
}
