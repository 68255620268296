@import "../../themes/colors.scss";

.container {
	.card {
		border-radius: 20px;
		padding: 16px;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $brownish-grey;

		.form {
			&Title {
				font-size: 48px;
				font-weight: bold;
			}

			&Description {
				font-size: 24px;
				font-weight: normal;
			}

			.section {
				&Header {
					margin-top: 12px;
				}

				&Title {
					font-size: 26px;
					font-weight: bold;
				}

				&Description {
					font-size: 18px;
					font-weight: normal;
				}
			}

			.button {
				margin-top: 35px;
				justify-content: flex-end;
			}
		}
	}
}
