@import "../../themes/colors.scss";

.sidebar {
	display: flex;
	min-height: 100vh;

	&BoxShadow {
		z-index: 1;
		box-shadow: 4px 0 10px 0 rgba(83, 92, 113, 0.1);
	}

	.logo {
		margin: 25px 0 40px 18px;
	}

	.tabs {
		width: 180px;
		background-color: $light-2;
		z-index: 0;

		.tab {
			font-size: 13px;
			line-height: 20px;
			text-transform: none;
			padding-left: 0;

			&Label {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				text-align: start;
				color: $dark;

				&Selected {
					> span {
						color: $dark-sky-blue;
						font-weight: 600;
					}

					.tabIcon {
						color: $dark-sky-blue;
					}
				}
			}

			&Icon {
				width: 60px;
				color: $light-3;
			}
		}
	}

	.content {
		background-color: $pale-gray;
		width: calc(100vw - 180px);
	}
}
