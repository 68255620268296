
@import "../../themes/colors.scss";

.functionPortal {
	margin: 5vh 0;
	padding: 99px 133px 126px 170px;
	background-color: $pale-gray;
	
	.functionPortalDescription {
		margin: 1vh 15vw;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			font-family: "Source Sans Pro";
			font-size: 38px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: $dark;
		}

		.description {
			font-family: "Source Sans Pro";
			font-size: 17px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.53;
			letter-spacing: normal;
			text-align: center;
			color: $dark;
		}
	}
}

.sectionTabContainer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;

	.image {
		&--right {
			order: 2;
		}
	}

	.descriptionContent {
		width: 572px;
		height: 234px;
		margin: 2vh 0 2vh 5vw;
		p {
			margin-bottom: 3vh;
			font-family: "Source Sans Pro";
			font-size: 17px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.53;
			letter-spacing: normal;
			color: $dark;
		}
	}
}