@import '../../themes/colors.scss';

.container{
	margin-bottom: 190px;

	.planTitle{
		margin: 56px 90px 56px 90px;
		font-family: "Source Sans Pro";
		font-size: 38px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.32;
		letter-spacing: normal;
		color: $dark;
	}
	
	.plansContainer {
		display: flex;
		justify-content: center;
	}
	
	.payment {
		margin: 56px 180px 56px 90px;
		font-family: "Source Sans Pro";
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $dark;
	
			.paymentTitle {
				font-size: 38px;
				font-weight: 600;
				line-height: 1.32;
			}
			.paymentDescription {
				font-size: 24px;
				font-weight: normal;
				line-height: normal;
			}
	}

	.paymentCard{
		margin: 10px 370px 10px;
	}
}
