@import "../../../themes/colors.scss";
@import "../../../themes/values.scss";

%topbar-links {
	opacity: 0.8;
	font-size: clamp(12px, 0.97vw, 14px);
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #fff;
}

.topbar {
	padding: 0;
	padding: 0 $default-padding-sides;
	background: linear-gradient(
		118deg,
		#45ddff,
		#4eadea 26%,
		#3ca8eb 27%,
		#4cb5ee 27%,
		#5593df 70%
	);
	height: $topbar-height;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
	position: relative;

	&Transparent {
		background: transparent;
	}

	.container {
		display: flex;
		align-items: center;

		.logoContainer {
			margin-right: clamp(73px, 10.14vw, 146px);
			width: 111px;

			.logo {
				width: 100%;
			}
		}

		.topbarLinks {
			flex-wrap: nowrap;

			.link {
				@extend %topbar-links;
				display: inline;
				text-decoration: none;
				margin-right: clamp(16px, 2.22vw, 32px);
			}

			.selectedLink {
				text-decoration: underline;
				text-decoration-thickness: clamp(1px, 0.14vw, 2px);
				text-underline-offset: clamp(4px, 0.56vw, 8px);
				text-underline-position: below;
			}

			@media (max-width: $first-break-point) {
				display: none;
			}
		}
	}

	.buttonContainer {
		display: flex;
		align-items: center;

		.button {
			border-radius: 24px;

			&:first-child {
				margin-right: clamp(7px, 0.97vw, 14px);
			}

			&Transparent {
				background-color: transparent;
				border-color: rgba($light-2, 0.8);
				color: $light-2;
			}
		}

		@media (max-width: $first-break-point) {
			display: none;
		}
	}

	.menuLogoContainer {
		display: none;

		@media (max-width: $first-break-point) {
			display: flex;
			align-items: center;
			width: 24px;

			.menuLogo {
				cursor: pointer;
				width: 100%;
			}
		}
	}
}

.menu {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #3ca8eb;
	z-index: 100;
	padding: $default-padding-sides;
	overflow-y: scroll;

	&Display {
		display: flex;
		flex-direction: column;
	}

	.closeMenuLogoContainer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		height: 24px;

		.closeMenuLogo {
			cursor: pointer;
			height: 100%;
		}
	}

	.topbarLinks {
		flex-direction: column;
		display: flex;
		align-items: center;

		.link {
			@extend %topbar-links;
			font-size: clamp(14px, 2.5vw, 24px);
			text-decoration: none;
			margin-top: clamp(40px, 12.5vw, 50px);
		}

		.selectedLink {
			text-decoration: underline;
			text-decoration-thickness: clamp(3px, 0.42vw, 6px);
			text-underline-offset: clamp(8px, 1.12vw, 16px);
			text-underline-position: below;
		}
	}

	.buttonContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: clamp(40px, 12.5vw, 50px);

		.button {
			border-radius: 24px;
			margin-top: clamp(8px, 2.5vw, 16px);

			.fixWidth {
				width: 50vw;
				height: 44px;
			}

			&Transparent {
				background-color: transparent;
				border-color: rgba($light-2, 0.8);
				color: $light-2;
			}
		}
	}
}
