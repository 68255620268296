@import "../../themes/colors.scss";

%flex-column {
	display: flex;
	flex-direction: column;
}

%page-container {
	min-height: 100vh;
	background-color: $container-background-color;
}

.signup {
	@extend %page-container;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);

	.signupContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 41vmin;
		margin: 5vmin 0;

		.signupLogoContainer {
			width: 16vmin;
			margin-bottom: 5vmin;

			.signupLogo {
				width: 100%;
			}
		}

		.signupTitle {
			font-size: calc(10px + 1.5vmin);
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: $textfield-font-color;
			margin-bottom: 3vmin;
		}

		.signupFormContainer {
			@extend %flex-column;
			width: 100%;

			.signupDivider {
				margin: 3vmin 0;
				display: flex;
				align-items: center;
			}

			.signupForm {
				@extend %flex-column;

				.formField {
					margin-bottom: 10px;
				}

				.signupError {
					display: flex;
					align-items: center;
					justify-content: center;
					// flex-wrap: wrap;
					height: 100px;
					border-radius: 2px;
					box-shadow: -2px 5px 6px 0 rgba(214, 217, 231, 0.29);
					background-color: $textfield-error-state-background-color;
					font-size: calc(10px + 0.4vmin);
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					padding: 10px;
					color: #474747;
				}

				.signupFooter {
					margin: 4vmin 0;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;

					.signupLink {
						font-size: calc(10px + 0.4vmin);
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: normal;
						color: $link-enabled;
					}
				}
			}
		}
	}
}
