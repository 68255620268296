$primary-color: #eee;
$secondary-color: #000;
$link-color: #24b7ee;

$base-background-color: #282c34;
$footer-main-color: #151f36;

$border-color: #dcdfeb;
$card-shadow: #dbe4f9;
$cornflower-blue: #587dd6;
$dark: #252f47;
$dark-sky-blue: #4ebed7;
$light-2: #ffffff;

$light-3: #d0d4d5;
$pale-gray: #f5f6fa;
$pale-grey-two: #f6f7f8;
$silver: #e7e9e9;

$filter-border-color: #dce4f7;
$dark-peach: #e86558;
$coral-red: #fe443f;
$blue-gray: #95969b;
$green-blue: #3fc08f;
$faded-orange: #f8a43e;
$smalt: rgba(2, 47, 143, 0.308);
$textfield-font-color: #696a72;
$textfield-error-state-background-color: #fce8e8;
$link-enabled: #587dd6;
$container-background-color: #f5f6fa;

$title-box-bg: #535c71;
$card-active-bg: #eef9fb;
$brownish-grey: #686868;
$btn-secondary-bg: #dee5f7;
$treatment-color-bg: #d8d8d8;

$signature-border-color: #e0e2e8;
$signature-background-color: #fafafa;