@import "../../../themes/colors.scss";

.availabilityModalTherapist {
	font-family: "Source Sans Pro";
	margin: 5px;

	.topContainer {
		margin: 10px 0 20px 0;
	}

	.medContainer {
		margin: 5px;
		display: flex;
		align-items: center;
	}
}

.title {
	color: $dark;
	font-size: 16px;
	font-weight: 700;
}

.text {
	color: $dark;
	font-size: 14px;
	font-weight: 600;
}
