@import "../../../themes/colors.scss";

.durationSelector {
	margin: 5px !important;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.buttonGroup {
		.button {
			height: 40px;
			&:disabled {
				background-color: lighten($color: $brownish-grey, $amount: 50);
			}
		}
		.buttonTime {
			margin: 3px;
			border: 1px solid $dark-sky-blue;
			border-radius: 20px;
			height: 20px;
			text-transform: none;
			&:disabled {
				border: none;
				background-color: lighten($color: $brownish-grey, $amount: 50);
			}
		}
	}

	svg {
		color: $cornflower-blue;
	}
}

.text {
	color: $dark;
	font-size: 14px;
	font-weight: 600;
}

.title {
	color: $dark;
	font-size: 16px;
	font-weight: 700;
}

.sideText {
	color: $cornflower-blue;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
}
