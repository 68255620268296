@import "../../themes/colors.scss";

.container {
	&Rounded {
		border-radius: 20px !important;

		.title {
			padding: 40px 40px 0;

			&Text {
				display: flex;
				font-family: "Source Sans Pro";
				font-size: 30px;
				font-weight: bold;
				color: $dark;

				.tertiaryButton {
					margin-left: 30px;
				}
			}

			&CloseIcon {
				position: absolute;
				top: 8px;
				right: 8px;
				color: $light-3;

				& > span > svg {
					width: 32px;
					height: 32px;
				}
			}
		}

		.content {
			overflow-x: hidden;
			margin: 30px 40px;
			padding: 17px 0px 0px;

			@media (min-width: 961px) {
				overflow-y: hidden;
			}
		}

		.divider {
			background-color: $light-3;
		}

		.dialogActions {
			padding: 0;
			margin: 0px 40px 30px;

			.actionsButtons {
				position: absolute;
				left: 0;
				padding-left: 30px;
				display: inline-flex;
				justify-content: space-between;
			}
		}
	}
}
