.content {
	.footer {
		display: flex;
		padding: 50px 0px;

		&Icon {
			margin-right: 20px;
		}

		&Text {
			flex-grow: 2;
		}
	}
}
