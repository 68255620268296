@import '../../../themes/colors.scss';

.active {
	box-shadow: 0px 0px 2px 1px $dark-sky-blue !important;
}

.card {
	margin-bottom: 10px;

	.content {
		display: flex;
		justify-content: space-around;
		align-items: center;
		
		.info {
			display: flex;
			flex: 1;
			justify-content: flex-start;
			align-items: center;

			.cardMedia {
			}

			.userName {
				display: flex;
				flex-direction: column;
				font-family: "Source Sans Pro";
				margin-left: 20px;
				.title {
					font-size: 16px;
					text-align: left;
				}
				.subTitle {
					color: $blue-gray;
					font-size: 12px;
					text-align: left;
				}
			}
		}
	}
}
