@import "../../themes/colors.scss";

.select {
	.root {
		font-size: 13px;
		color: $blue-gray;
		background-color: rgba($light-2, 0.64);
	}

	.icon {
		color: $cornflower-blue;
	}
}
