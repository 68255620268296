@import "../../../../../../../themes/colors.scss";

.confirmation {
	.card {
		background-color: $light-2;
		border: none;
		box-shadow: none;
		border-radius: 20px;
		padding: 60px 45px;
		margin-top: 20px;

		.alert {
			&Container {
				max-width: 500px;
				margin-bottom: 22px;
			}

			&Item {
				&Root {
					padding: 0px 14px;
					border-radius: 20px;
					background-color: $pale-gray;
					font-size: 16px;
					color: $dark;
				}

				&Action {
					color: $dark-peach;
				}
			}

			&Icon {
				color: $light-3;
			}
		}
	}
}
