.info {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;

	.infoContainer {
		display: flex;
		flex-direction: column;

		> div:first-child {
			margin-bottom: 12px;
		}

		.sendMessageBtn {
			text-align: center;
		}

		width: 393px;
		margin: 0px 20px 20px 0px;
	}

	.notesContainer {
		display: flex;
		flex-grow: 1;
		flex-basis: 550px;
		margin: 0px 0px 20px;
	}
}
