@import "../../themes/colors.scss";

.tagContainer {
	box-sizing: border-box;
	max-width: 200px;
	text-align: center;
	height: 35px;
	padding: 8px 20px;
	border-radius: 17px;
	background-color: #edf8fb;

	.tagText {
		font-family: "Source Sans Pro";
		font-size: 15px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		opacity: 1;
		color: $dark-sky-blue;
	}
}
