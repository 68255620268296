@import "../../../themes/colors.scss";

.resourceCard {
	margin-bottom: 10px;

	&Area {
		height: 64px;
	}

	&Content {
		display: flex;
		height: 32px;
		justify-content: space-between;

		&Items {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.bookmarkIcon {
				position: absolute;
				top: -6px;
				left: 12px;
				color: $faded-orange;
			}

			.primaryIcon {
				font-size: 17px;
				color: $light-3;
				margin-right: 10px;
			}

			.primaryText {
				font-family: "Source Sans Pro";
				font-size: 16px;
				color: $dark;
			}

			.secondaryIcon {
				font-family: "Source Sans Pro";
				font-size: 19px;
				margin-right: 8px;

				&Check {
					color: $green-blue;
				}

				&Complete {
					width: 18px;
					height: 18px;
					background-color: $cornflower-blue;

					&Arrow {
						width: 70%;
						height: 70%;
						color: $light-2;
					}
				}
			}

			.secondaryText {
				font-family: "Source Sans Pro";
				font-size: 13px;
				margin-right: 5px;

				&Check {
					color: $cornflower-blue;
				}

				&Complete {
					opacity: 0.5;
				}
			}
		}
	}
}

.active {
	border: solid 1px $dark-sky-blue !important;
	background-color: $card-active-bg !important;

	& .primaryIcon {
		color: $dark-sky-blue;
	}
}
