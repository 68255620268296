@import "../../../../themes/colors.scss";

.noteDetail {
	min-width: 170px;
	flex: 1;

	.card {
		border: none;
		border-radius: 20px;
		background-color: $pale-grey-two;
		height: 100%;

		.content {
			padding: 30px 20px 30px 40px;
			color: $dark;
			font-size: 13px;

			.titleContainer {
				display: flex;
				justify-content: space-between;

				.title {
					font-weight: bold;
					text-transform: uppercase;
					padding-top: 10px;
				}

				.editButton {
					font-weight: bold;
					font-size: 13px;
				}
			}

			.description {
				margin-top: 20px;
				font-weight: normal;
			}
		}
	}
}
