@import "../../themes/colors.scss";

.sectionTitle {
	.text {
		display: flex;
		justify-content: space-between;

		p {
			font-family: "Source Sans Pro";
			font-size: 13px;
			font-weight: bold;
			color: $dark;
			text-transform: uppercase;
		}
	}

	.divider {
		height: 3px;
		color: $cornflower-blue;
		margin-bottom: 15px;
	}
}
