@import "../../../../themes/colors.scss";

.sideAgendaMenu {
	.boxInfo {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		
		span:nth-child(2) {
			font-size: 18px;
			font-weight: 600;
		}
	}

	.list {
		max-height: 60%;
		overflow: auto;

		.selected {
			background-color: rgba($dark-sky-blue, 0.15);
			border: solid 1px $dark-sky-blue;
			box-shadow: 0 2px 4px 0 $card-shadow;
			border-radius: 6px;
		}

		.icon {
			color: $cornflower-blue;
		}
	}

	.listAddItem {
		color: $cornflower-blue;
		font-size: 13px;
		
		.itemWeight {
			font-weight: 600;
		}		
	}

	.listHeader {
		font-weight: 600;
	}
}
