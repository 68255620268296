@import "../../index.scss";

.horizontalRuler {
	border: 1px solid #cfd6e9;
	display: inline-block;
	height: 0px;
	flex: 1;
}

.rulerText {
	color: #7d7d7d;
	font-size: calc(10px + 1vmin);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	margin: 0 2vmin;
}
