@import "../../../themes/colors.scss";

%transition-style {
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.profilePicture {
	.cardImage {
		max-width: 190px;
		padding: 30px 101px;
		position: relative;
		border: none;
		box-shadow: none;
		border-radius: 20px;

		.cardActionArea {
			border-radius: 50%;

			&:hover {
				.cardActionAreaDetails,
				.cardActionAreaOverlay {
					opacity: 1;
				}
			}

			&Details {
				color: $light-2;
				position: absolute;
				text-align: center;
				width: 100%;
				top: 50%;
				left: 50%;
				opacity: 0;
				-webkit-transform: translate(-50%, -50%);
				-moz-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				text-align: -webkit-center;
				@extend %transition-style;

				&Icon {
					display: flex;
				}
			}

			&Overlay {
				border-radius: 50%;
				background: rgba(0, 0, 0, 0.5);
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				opacity: 0;
				@extend %transition-style;

				&:hover {
					opacity: 1;
				}
			}
		}

		.cardMedia {
			height: 190px;
			width: 190px;
			border-radius: 50%;
		}

		.text {
			color: $dark;
			font-size: 13px;
			letter-spacing: normal;
			font-size: 18px;
			font-weight: 600;
			text-align: center;
		}
	}
}
