@import "../../themes/colors.scss";
@import "../../themes/values.scss";

.blogCardContainer {
	width: 100%;

	.image {
		width: 100%;
		height: auto;
	}

	.blogPostContent {
		position: relative;
		margin-top: -115px;
		margin-left: 2.6vw;
		margin-right: 2.6vw;
		box-shadow: 0 2px 3px 0 $card-shadow;
		padding: 35px;

		@media (max-width: $first-break-point) {
			margin: 0;
			box-shadow: none;
		}

		.tagContainer {
			display: flex;
			flex-flow: row nowrap;

			.tag {
				margin-right: 5px;
			}
		}

		.title {
			margin-top: clamp(16px, 1.25vw, 18px);
			margin-bottom: 0;
			font-family: "Source Sans Pro";
			font-size: clamp(20px, 1.8vw, 26px);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $dark;
			overflow: hidden;
		}

		.summary {
			margin-top: clamp(8px, 1.25vw, 18px);
			margin-bottom: 0;
			font-family: "Source Sans Pro";
			font-size: clamp(12px, 1.2vw, 17px);
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.53;
			letter-spacing: normal;
			color: $dark;
			overflow: hidden;
			height: 100px;
		}

		.moreContainer {
			margin-top: clamp(16px, 1.25vw, 18px);

			.more {
				text-decoration: none;
				font-family: "Source Sans Pro";
				font-size: clamp(12px, 0.97vw, 14px);
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: $cornflower-blue;
			}
		}
	}
}
