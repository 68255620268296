@import "../../themes/colors.scss";
@import "../../themes/values.scss";

.form {
	box-shadow: 0 10px 18px 0 #222c46;
	background-color: $light-2;
	font-family: "Source Sans Pro";
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	color: $dark;
	margin-top: 10px;
	width: 28vw;
	height: 560px;

	@media (max-width: $first-break-point) {
		width: 87.5vw;
		padding: 0;
		min-height: unset;
		height: unset;
	}

	.formLayout {
		@media (max-width: $first-break-point) {
			padding: clamp(25px, 3.5vw, 50px);
		}

		.formTitle {
			font-size: clamp(20px, 4.5vw, 22px);
			font-weight: 600;
			margin: 0;
		}

		.formSubtitle {
			margin-top: clamp(4px, 0.7vw, 10px);
			margin-bottom: clamp(20px, 4.7vw, 25px);
			font-size: clamp(12px, 1.2vw, 17px);
			font-weight: 300;
			line-height: 1.53;
		}

		.formComponentWrapper {
			margin-bottom: 1vh;

			&:last-child {
				margin-top: clamp(20px, 1.5vw, 21px);
			}
		}
	}
}
