.inputMessage {
	width: 100%;
	padding: 10px;
	font-size: 16px;
	box-sizing: border-box;
}

.inputMessageProps {
  text-align: start;
}
