@import '../../themes/colors.scss';

.container {
	background-color: transparent !important;
	border: 0px !important;
	box-shadow: none;

	.tabContainer {
		color: $dark !important;
		text-transform: none !important;
		border-bottom: 2px solid rgba(88, 125, 214, .1);

		span {
			text-transform: none !important;
			font-family: 'Source Sans Pro' !important;
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
		}
	}

	.tabContainerSelected {
		color: $cornflower-blue  !important;
	}
}

.containerTab {
	padding: 5vw 1vw 5vw 1vw;
}

.tabIndicator {
	background: $cornflower-blue !important;
}