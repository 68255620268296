@import "../../../../themes/colors.scss";

.therapistButtons {
	display: flex;
	flex-grow: 1;
	align-items: center;
	margin-left: 34px;

	.primaryButton + .secondaryButton {
		margin-left: 20px;
	}

	.search {
		display: flex;
		align-items: center;
		color: $blue-gray;

		.divider {
			margin: 25px;
			height: 28px;
			align-self: auto;
		}

		.icon {
			padding-right: 16px;
		}
	}
}
