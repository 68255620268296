@import '../../../themes/colors.scss';

.invitationContent {
	.content {
		align-content: flex-start;
		background-color: $pale-gray;
		column-gap: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		min-height: 100vh;
		padding: 4% 8% 4% 8%;
		row-gap: 20px;

		.cardImage {
			padding: 34px 44px;
		}

		.noInvitations {
			font-size: 30px;
			font-weight: bold;
			color: $dark;
		}
	}
}
