@import "../../../themes/colors.scss";

.content {
	.title {
		font-size: 18px;
		font-weight: 600;
		color: $dark;
		text-align: center;
	}

	.subtitle {
		font-size: 13px;
		color: $dark;
		text-align: center;
		margin-top: 20px;
	}

	.alert {
		margin-top: 21px;
		padding: 0 16px;
		border-radius: 20px;
		font-size: 13px;
		color: $brownish-grey;
		border: solid 1px rgba(248, 164, 62, 0.3);
	}

	.divider {
		margin-top: 37px;
		margin-bottom: 30px;
	}
}
