@import "../../themes/colors.scss";

%disabled-style {
	color: $light-2;
	background-color: rgba($secondary-color, 0.12);
}

%text-style {
	border-radius: 21px;
	text-transform: unset;
	box-shadow: none;

	&.disabled {
		@extend %disabled-style;
	}
}

.container {
	.button {
		font-family: "Source Sans Pro";
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;

		&.primary {
			@extend %text-style;
			color: $light-2;
			background-color: $cornflower-blue;
		}

		&.secondary {
			@extend %text-style;
			color: $cornflower-blue;
			background-color: $btn-secondary-bg;
		}

		&.light {
			@extend %text-style;
			color: $cornflower-blue;
			background-color: $light-2;
			border: solid 1px $cornflower-blue;
		}

		&.tertiary {
			font-size: 14px;
			border-radius: 24px;
			border: solid 2px $light-2;
			background-color: $light-2;
			color: $cornflower-blue;
			text-transform: unset;

			&.disabled {
				@extend %disabled-style;
			}
		}
	}
}
