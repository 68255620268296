@import "../../themes/colors.scss";

$card-width: clamp(394px, 37vw, 537px);
$img-width: calc(0.37 * #{$card-width});
$card-width-half: clamp(197px, 18.5vw, 268.5px);
$img-width-half: calc(0.37 * #{$card-width-half});

.cardContainer {
	width: $card-width;
	margin: (#{$img-width-half}) 0 0;
	box-shadow: 0 2px 3px 0 $card-shadow;
	padding: 0 clamp(25px, 2.5vw, 35px);
	height: 350px;
	display: block;

	.imageContainer {
		width: $img-width;
		height: $img-width;
		position: relative;
		top: calc(-1 * #{$img-width-half});
		left: calc(#{$card-width-half} - #{$img-width-half});
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		overflow: hidden;

		.image {
			width: 100%;
		}
	}

	%textCommon {
		font-family: "Source Sans Pro";
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
		color: $dark;
		position: relative;
		top: -5vw;
	}

	.cardTitle {
		@extend %textCommon;
		margin: 0;
		font-size: 22px;
		font-weight: 600;
		line-height: normal;
	}

	.cardSubtitle {
		@extend %textCommon;
		font-size: 13px;
		font-weight: 700;
		line-height: normal;
	}

	.cardDescription {
		@extend %textCommon;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.57;
		overflow: hidden;
	}
}
