@import "../../../../../../../themes/colors.scss";

.information {
	.card {
		margin-top: 40px;
		padding: 40px;
		border: none;
		box-shadow: none;
		border-radius: 20px;

		.row {
			.content {
				display: flex;
				align-items: center;
				justify-content: space-between;

				p {
					font-size: 13px;
				}

				.label {
					display: flex;
					align-items: center;
					color: rgba($dark, 0.5);

					.checkbox {
						color: rgba($dark, 0.5);

						&Checked {
							color: $cornflower-blue;
						}
					}

					&.selectAll {
						p {
							font-weight: 600;
							color: $cornflower-blue;
						}
					}
				}
			}
		}
	}
}
