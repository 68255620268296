@import "../../themes/colors.scss";

.functionSecurity {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $pale-gray;
	padding-bottom: 25vh;
	padding-top: 13vh;
	border-top-left-radius: 50% 13vh;
	border-top-right-radius: 50% 13vh;
	margin-top: -13vh;

	.functionSecurityTitle {
		margin-top: 10vh;
		font-family: "Source Sans Pro";
		font-size: 38px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: $dark;
	}

	.functionSecurityDescription {
		margin: 5vh 25vw;
		.title {
			font-family: "Source Sans Pro";
			font-size: 38px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: $dark;
		}

		.description {
			font-family: "Source Sans Pro";
			font-size: 17px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.53;
			letter-spacing: normal;
			text-align: center;
			color: $dark;
		}
	}

	.functionSecurityCard {
		max-width: 836px;

		.internal {
			width: 666px;
			height: 1px;
			margin: 13px 0;
			background-color: $pale-gray;
		}

		.cardContainer {
			box-sizing: border-box;
			display: flex;
			justify-content: space-evenly;
			flex-direction: row;
			padding: 0;

			.cardimage{
				height: calc(3vmax + 50px);
				width: calc(3vmax + 50px);
			}

			.cardTitle {
				font-family: "Source Sans Pro";
				font-size: 26px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: $dark;
			}
		}

		.functionInfraCard {
			display: flex;
			justify-content: flex-start;
			flex-direction: row;	
			margin: 1vw;

			.cardimage{
				height: calc(2vmax + 2px);
				width: calc(2vmax + 2px);
				margin-right: 5vw;
				margin-left: 4vw;
			}

			.cardTitle {
				width: 40vw;
				font-family: "Source Sans Pro";
				font-size: 17px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.53;
				letter-spacing: normal;
				color: $dark;	
				margin: 0;

			}
		}
	}	
}
