@import "../../../themes/colors.scss";

.resourceCard {
	height: 64px;
	margin-bottom: 10px;
	box-shadow: 0 2px 4px 0 $card-shadow;

	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}

	&NoData {
		font-size: 16px;
		font-weight: bold;
		color: $brownish-grey;
		text-align: center;
	}

	&Area {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	&Content {
		display: flex;
		// padding: 24px 16px !important;
		height: 100%;
		padding: 0px 16px !important;
		// align-items: center;

		&Items {
			display: flex;
			flex-grow: 1;
			align-items: center;

			.checkbox {
				color: $light-3;
				margin-left: -5px;

				&Checked {
					color: $dark-sky-blue;
				}

				& + .bookmarkIcon {
					left: 45px;
				}
			}

			.bookmarkIcon {
				position: absolute;
				top: -6px;
				left: 12px;
				color: $faded-orange;
			}

			.primaryIcon {
				font-size: 17px;
				color: $light-3;
				margin-right: 10px;
			}

			.primaryText {
				font-size: 16px;
				color: $dark;
			}
		}
	}
}

.active {
	border: solid 1px $dark-sky-blue !important;
	background-color: $card-active-bg !important;

	& .primaryIcon {
		color: $dark-sky-blue;
	}
}
