@import "../../../themes/colors.scss";

.therapistCard {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	border-radius: 6px !important;

	.cardContent {
		display: flex;

		&Simple {
			border: solid 1px $pale-gray;
			box-shadow: 0 2px 4px 0 $card-shadow;
		}

		&Selected {
			background-color: rgba($dark-sky-blue, 0.15);
			border: solid 1px $dark-sky-blue;
			box-shadow: 0 2px 4px 0 $card-shadow;
			border-radius: 6px;
		}

		.title {
			display: flex;
			flex-direction: column;
			font-family: "Source Sans Pro";
			font-size: 16px;
			color: $dark;
			padding-left: 20px;

			span {
				font-size: 13px;
				color: $dark;
				opacity: 0.5;
			}
		}
	}
}

.therapistCardNoData {
	font-family: "Source Sans Pro";
	font-size: 16px;
	font-weight: bold;
	color: $brownish-grey;
	text-align: center;
}
