.sessionModal {
	&DatePicker {
		display: flex;
		justify-content: center;
	}

	&DateCards {
		overflow-y: auto;
		margin-right: 8px;

		@media (min-width: 961px) {
			height: 350px;
		}
	}
}
