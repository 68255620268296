@import "../../../themes/colors.scss";

.library {
	.container {
		padding: 26px;

		.appBar {
			background-color: transparent;
			border: none;
			border-bottom: 3px solid rgba($cornflower-blue, 0.1);

			.tabs {
				&Indicator {
					display: flex;
					height: 3px;
					justify-content: center;
					background-color: transparent;

					& > span {
						max-width: 50%;
						width: 100%;
						background-color: $cornflower-blue;
					}
				}

				.tab {
					text-transform: none;
					color: $dark;
					font-family: "Source Sans Pro";
					font-size: 13px;
					font-weight: bold;
					padding: 0 30px;

					&:focus {
						color: $cornflower-blue;
					}
				}
			}
		}
	}
}
