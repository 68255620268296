@import "../../../../../themes/colors.scss";

.documentation {
	.iconFile {
		color: $light-3;
	}

	.iconActions {
		color: $light-3;
	}

	.sharedAvatar {
		width: 30px;
		height: 30px;
		font-size: 15px;
		font-weight: bold;		
	}

	.avatar {
		display: flex;

		&Content {
			font-size: 15px;
			font-weight: bold;
			width: 30px;
			height: 30px;

			&Patient {
				background-color: $green-blue;
				color: $light-2;
			}

			&Therapist {
				background-color: rgba($green-blue, 0.3);
				color: $green-blue;
			}
		}
	}
}
