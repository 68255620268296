@import "../../../../../../themes/colors.scss";

.createReport {
	.container {
		padding: 35px 32px;

		.actionButton {
			font-size: 13px;
			text-transform: unset;
			font-weight: 600;
		}

		.stepper {
			margin-top: 35px;
			padding: 10px;
			border-radius: 20px;

			.step {
				&Button {
					font-size: 13px;
					font-weight: bold;

					&Title {
						font-size: 13px;
						font-weight: bold;
						text-transform: uppercase;
						color: $dark;
					}
				}
			}
		}

		.content {
			padding-top: 41px;

			.footer {
				margin-top: 44px;
				display: flex;
				justify-content: flex-end;

				& div {
					margin-left: 10px;

					& button {
						min-width: 140px;
					}
				}
			}
		}
	}
}
