@import "../../themes/colors.scss";
@import "../../themes/values.scss";

.container {
	background-color: $dark;
	padding-top: 13vh;
	border-top-left-radius: 50% 9.75vw;
	border-top-right-radius: 50% 9.75vw;
	margin-top: -13vh;
	padding-bottom: 8vh;

	@media (max-width: $first-break-point) {
		min-height: 695px;
	}

	.title {
		text-align: center;
		font-family: "Source Sans Pro";
		font-size: 38px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $light-2;
	}

	.cards {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-evenly;

		%card {
			height: 560px;
			width: 28vw;
			box-shadow: 0 10px 18px 0 #222c46;

			@media (max-width: $first-break-point) {
				width: 87.5vw;
				padding: 0;
				min-height: unset;
				height: unset;
				margin-top: 4vh;
			}
		}

		.linkCard {
			@extend %card;
			background-image: radial-gradient(
					30vw 30vw at 85% 100%,
					#519fe4 40%,
					#4eaeeb 90%,
					$cornflower-blue 91%,
					transparent
				),
				linear-gradient(300deg, #5781d7 80%, #4eaae9);
			margin-top: 10px;

			@media (max-width: $first-break-point) {
				min-height: 87 vw;
			}

			.logo {
				height: 6vh;
				display: block;
				margin: 9vh auto 5vh;
			}

			%cardText {
				color: $light-2;
				font-family: "Source Sans Pro";
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
			}

			.title {
				@extend %cardText;
				font-size: clamp(20px, 2.6vw, 38px);
				font-weight: 600;

				.bold {
					font-weight: 900;
				}
			}

			.subtitle {
				@extend %cardText;
				margin: 4vh clamp(0px, 1.9vw, 27px) 0;
				font-size: clamp(12px, 1.6vw, 24px);
				font-weight: normal;

				@media (max-width: $first-break-point) {
					margin-bottom: 5vh;
				}
			}
		}
	}
}
