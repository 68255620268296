@import "../../themes/colors.scss";

.container {
	min-height: 290px;
	height: 100%;

	.card {
		border-radius: 20px;
		padding: 16px;
		height: 100%;

		.pdfContainer {
			text-align: -webkit-center;
			position: relative;
			height: 320px;
			overflow: auto;
			justify-content: center;

			.floatButton {
				background-color: $pale-gray;
				box-shadow: 0 4px 4px 0 $card-shadow;
				color: $title-box-bg;
				position: absolute;
				top: 20px;
				right: 20px;

				&:hover {
					color: $dark;
				}
			}

			.pdf {
				&:hover {
					cursor: pointer;
					opacity: 0.8;
				}
			}
		}

		.title {
			font-family: "Source Sans Pro";
			font-size: 28px;
			font-weight: bold;
			color: $brownish-grey;
		}

		.subtitle {
			font-family: "Source Sans Pro";
			font-size: 13px;
			font-weight: bold;
			color: $dark;
			text-transform: uppercase;
		}

		.text {
			font-family: "Source Sans Pro";
			font-size: 18px;
			color: $brownish-grey;
		}
	}
}

.titleBox {
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;

	> span {
		font-size: 16px;
		font-weight: normal;
		margin-left: 18px;
		text-transform: none;
	}
}

.modalContainer {
	text-align: -webkit-center;
	width: 100%;
}
