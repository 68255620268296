@import "../../themes/colors.scss";

.mainContainer {
	margin: 3.5vh 0 9.5vh 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;

	.container {
		width: 42vw;

		.mainTitle {
			font-family: "Source Sans Pro";
			font-size: 38px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.32;
			letter-spacing: normal;
			color: $dark;
		}

		.mainDescription {
			font-family: "Source Sans Pro";
			font-size: 17px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.53;
			letter-spacing: normal;
			color: $dark;
		}
	}

	.mainImage {
		height: 48vh;
		top: -5vw;
		right: 9.5vw;
	}
}

.planTitle {
	margin-left: 7vw;
	margin-bottom: 3vh;
	font-family: "Source Sans Pro";
	font-size: 38px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.32;
	letter-spacing: normal;
	color: $dark;
}

.treatmentContainer {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	margin: 0 6vw 20vh;

	.card {
		margin: 2vh 0.5vw 1vh;
		padding: 2vh 0.5vw 2vh;
		border-radius: 4.8px;
		box-shadow: 0 2px 3px 0 #dbe4f9;
		border: solid 0.8px #e0e7f9;
		background-color: $light-2;
	}

	.rectangle {
		width: 8vw;
		height: 14vh;
		margin: 0 3vw 1vh;
		background-color: $treatment-color-bg;
	}

	.cardDescription {
		.treatment_card_title {
			margin: 0.7vh 3vw 0.5vh 0;
			font-family: "Source Sans Pro";
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.86;
			letter-spacing: normal;
			color: $dark;
		}

		.treatment_card_description {
			width: 15vw;
			font-family: "Source Sans Pro";
			font-size: 14px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.57;
			letter-spacing: normal;
			color: $dark;
		}
	}
}
